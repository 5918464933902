import { useState } from 'react';
import Checkbox from '../../../components/Checkbox';

export const FormRgpdCadidateProfile = (props: {getData: any, value: any}) => {
  const [contactByEmail, setContactByEmail] = useState<boolean>(props.value.rgpd.contactByEmail);
  const [contactByWhatsapp, setContactByWhatsapp] = useState<boolean>(props.value.rgpd.contactByWhatsapp);

  return (
    <div>
      <Checkbox id="contact-email" checked={contactByEmail} name="contactByEmail" label="Contact By E-mail" onChange={(e) => {props.getData(e, "rgpd"); setContactByEmail(e.target.checked)}} />
      <Checkbox id="contact-whatsapp" checked={contactByWhatsapp} name="contactByWhatsapp" label="Contact by WhatsApp" onChange={(e) => {props.getData(e, "rgpd"); setContactByWhatsapp(e.target.checked)}} />
    </div>
  )
}
