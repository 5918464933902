import Input from '../../../components/Input'
import Select from '../../../components/Select'

const simpleSelect = [
  { label: 'None', value: 'none' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const referenceSelect = [
  { label: 'None', value: 'none' },
  { label: 'Hacker', value: 'hacker' },
  { label: 'Others', value: 'others' },
];

export const FormHiringDetailCandidateProfile = (props: {countriesDataSelect: {label: string, value: string}[],  getData: any, value: any}) => {
  
  return (
    <div className="form__candidate-optional">
      <Input id="intended-position" type="text" value={props.value.hiringDetail.intendedPosition} placeholder="" label="Intended Position" required={true} name="intendedPosition" onChange={(e) => props.getData(e, "hiringDetail")} />
      <Input id="net-salary" type="number" value={props.value.hiringDetail.netSalary} placeholder="" label="Net Salary Expectation(days)" required={true} name="netSalary" onChange={(e) => props.getData(e, "hiringDetail")} />
      <Input id="availability-in-loco" type="number" value={props.value.hiringDetail.availabilityInLoco} placeholder="" label="Availability In Loco(days)" required={true} name="availabilityInLoco" onChange={(e) => props.getData(e, "hiringDetail")} />
      <Input id="availability-remote" type="number" value={props.value.hiringDetail.availabilityRemote} placeholder="" label="Availability Remote(days)" required={true} name="availabilityRemote" onChange={(e) => props.getData(e, "hiringDetail")} />
      <Select 
        label="Passport"
        id="passport" 
        value={props.value.hiringDetail.passport} 
        name="passport"
        options={simpleSelect} 
        onChange={(e) => props.getData(e, "hiringDetail")}
      />
      <Select 
        label="Degree Documentation"
        id="degree" 
        value={props.value.hiringDetail.degreeDocumentation} 
        name="degree"
        options={simpleSelect} 
        onChange={(e) => props.getData(e, "hiringDetail")}
      />
      <Select 
        label="Reference"
        id="reference" 
        value={props.value.hiringDetail.reference} 
        name="reference"
        options={referenceSelect} 
        onChange={(e) => props.getData(e, "hiringDetail")}
      />
    </div>
  )
}
