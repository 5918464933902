import { useContext, useEffect, useState } from 'react';
import { monthsData, yearsData } from '../../utils/monthYearJson';
import { coutriesData } from '../../utils/countriesJson';
import { languagesData } from '../../utils/languagesJson';
import UserContext from '../../context/UserContext';
import { IUser } from '../../interfaces/IUser';
import CompetencesContext from '../../context/CompetencesContext';
import { IListCompetences } from '../../interfaces/IListCompetences';

import { useNavigate } from 'react-router-dom';

import FormAddressInformationCadidateProfile from './FormAddressInformationCadidateProfile';
import FormBasicInfoCandidateProfile from './FormBasicInfoCadidateProfile';
import FormHiringDetailCandidateProfile from './FormHiringDetailCandidateProfile';
import FormCompetenciesCandidateProfile from './FormCompetenciesCandidateProfile';
import FormLanguagesCandidateProfile from './FormLanguagesCandidateProfile';
import FormCertificationCandidateProfile from './FormCertificationCandidateProfile';
import FormExperienceCandidateProfile from './FormExperienceCandidateProfile';
import FormEducationalCandidateProfile from './FormEducationalCandidateProfile';
import FormRgpdCadidateProfile from './FormRgpdCadidateProfile';
import DataBasicInfoCandidateProfile from '../DataCandidateProfile/DataBasicInfoCandidateProfile';
import DataCompetenciesCandidateProfile from '../DataCandidateProfile/DataCompetenciesCandidateProfile';
import DataLanguageCandidateProfile from '../DataCandidateProfile/DataLanguageCandidateProfile';
import DataExperienceCandidateProfile from '../DataCandidateProfile/DataExperienceCandidateProfile';
import DataEducationalCandidateProfile from '../DataCandidateProfile/DataEducationalCandidateProfile';
import DataCertificationCandidateProfile from '../DataCandidateProfile/DataCertificationCandidateProfile';
import DataAddressInformationCandidateProfile from '../DataCandidateProfile/DataAddressInformationCandidateProfile';
import DataHiringDetailCandidateProfile from '../DataCandidateProfile/DataHiringDetailCandidateProfile';
import DataRgpdCandidateProfile from '../DataCandidateProfile/DataRgpdCandidateProfile';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Error from '../../components/Error';
// import FormAttachmentCandidateProfile from './FormAttachmentCandidateProfile';
// import DataAttachmentCandidateProfile from '../DataCandidateProfile/DataAttachmentCandidateProfile';
import { sendUpdateUser } from '../../services/user';

export const FormCadidateProfile = () => {
  const { user, setUser } = useContext(UserContext);
  const { competences, setCompetences } = useContext(CompetencesContext);

  const navigate = useNavigate();
  const is_auth = localStorage.getItem('access_token');

  const [countrySelect, setCountrySelect] = useState([{label: 'None', value: 'None'}]);
  const [monthSelect, setMonthSelect] = useState([{label: 'Month', value: 'Month'}]);
  const [yearSelect, setYearsSelect] = useState([{label: 'Year', value: 'Year'}]);
  const [languageSelect, setLanguageSelect] = useState([{label: 'None', value: 'None'}]);

  const [updateUser, setUpdateUser] = useState<IUser | undefined>(undefined);
  const [updateCompetences, setUpdateCompetences] = useState<IListCompetences | undefined>(undefined);

  const [updateBasicInfo, setUpdateBasicInfo] = useState(false);
  const [updateCompetencies, setUpdateCompetencies] = useState(false);
  const [updateLanguages, setUpdateLanguages] = useState(false);
  const [updateExperience, setUpdateExperience] = useState(false);
  const [updateEducational, setUpdateEducational] = useState(false);
  const [updateCertification, setUpdateCertification] = useState(false);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [updateHiring, setUpdateHiring] = useState(false);
  const [updateAttachment, setUpdateAttachment] = useState(false);
  const [updateRGPD, setUpdateRGPD] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const allCountries = coutriesData();
  const allMonths = monthsData();
  const allYears = yearsData();
  const allLanguages = languagesData();

  useEffect(() => {
    setUpdateUser(user);
    setUpdateCompetences(competences);
  }, []);

  useEffect(() => {
    setUpdateCompetences(competences);
  }, [competences]);

  useEffect(() => {
    setCountrySelect([...countrySelect as any, ...allCountries]);
    setMonthSelect([...monthSelect as any, ...allMonths]);
    setYearsSelect([...yearSelect as any, ...allYears]);
    setLanguageSelect([...languageSelect as any, ...allLanguages]);
    setUpdateUser(user);
    setUpdateCompetences(competences);
  }, [user, is_auth]);

  const onChangeFormChild = (e: any, formPart: never) => {
    type part = keyof typeof updateUser;
    let partType: part = formPart;
    if(updateUser && e.name === 'mobile') {
      setUpdateUser({
        ...updateUser,
        [partType]: {
          ...updateUser?.[partType] as unknown as object,
          [e.name]: e.value
        }
      });
    }
    if(updateUser && e.name !== 'mobile' && partType !== 'attachmentInfo'){
      setUpdateUser({
        ...updateUser,
        [partType]: {
          ...updateUser?.[partType] as unknown as object,
          [e.target.name]: e.target.value
        }
      });
    }
    if(updateUser && partType === 'rgpd'){
      setUpdateUser({
        ...updateUser,
        [partType]: {
          ...updateUser?.[partType] as unknown as object,
          [e.target.name]: e.target.checked
        }
      });
    }
    if(updateUser && partType === 'attachmentInfo'){
      setUpdateUser({
        ...updateUser,
        [partType]: {
          ...updateUser?.[partType] as unknown as object,
          "attachement": e.target.files[0],
          "name": e.target.files[0].name
        }
      });
    }
  }

  const onChangeFormGroupChild = (setObj: any, formPart: never) => {
    type part = keyof typeof updateUser;
    let partType: part = formPart;
    if(updateUser && partType !== 'attachmentCertification') {
      setUpdateUser({
        ...updateUser,
        [partType]: [...updateUser?.[partType] as unknown as string].concat(setObj)
      });
    }
    if(updateUser && partType === 'attachmentCertification'){
      setUpdateUser({
        ...updateUser,
        [partType]: {
          ...updateUser?.[partType] as unknown as object,
          "attachement": setObj.target.files[0],
          "name": setObj.target.files[0].name,
          "id": setObj.target.files[0].id
        }
      });
    }
  }

  const onChangeDeleteChild = (setObj: any, formPart: never) => {
    type part = keyof typeof updateUser;
    let partType: part = formPart;
    setUpdateUser({
      ...updateUser,
      [partType]: setObj
    } as IUser);
  }

  const handleBackClick = () => {
      navigate('/jobs-opportunities/');
  }

  const submitForm = async () => {
    setSubmitDisabled(true);
    const update = await sendUpdateUser(updateUser);
    setErrorUpdate(update.message);
    setUser(updateUser);
    setUpdateBasicInfo(false);
    setUpdateCompetencies(false);
    setUpdateLanguages(false);
    setUpdateExperience(false);
    setUpdateEducational(false)
    setUpdateCertification(false);
    setUpdateAddress(false);
    setUpdateHiring(false);
    setUpdateAttachment(false);
    setUpdateRGPD(false);
    setSubmitDisabled(false);
  }

  return (
    <form className="form__candidate" method="post" encType="multipart/form-data">
      <h2 className="form__candidate-title form__candidate-title--edit">
        Basic Info
        {!updateBasicInfo ?
          <Icon
            icon="edit"
            onClick={() => setUpdateBasicInfo(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateBasicInfo(false)}
          />
        }
      </h2>
      {updateBasicInfo ?
        <FormBasicInfoCandidateProfile
          countriesDataSelect={countrySelect}
          getData={onChangeFormChild}
          value={updateUser?.basicInfo}
        />
      : <DataBasicInfoCandidateProfile />
      }

      <h2 className="form__candidate-title form__candidate-title--edit">
        Competencies
        {!updateCompetencies ?
          <Icon
            icon="edit"
            onClick={() => setUpdateCompetencies(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateCompetencies(false)}
          />
        }
      </h2>
      <div className='form__candidate-subtitle'>
        Add your professional skills i.e. Java; Springboot; .Net
      </div>

      <DataCompetenciesCandidateProfile
        removeIcon={updateCompetencies}
        getData={onChangeDeleteChild}
        value={updateUser?.competencies}
      />
      {updateCompetencies &&
        <FormCompetenciesCandidateProfile
          getData={onChangeFormGroupChild}
          value={updateUser?.competencies}
          listCompetencies={updateCompetences}
        />
      }

      <h2 className="form__candidate-title form__candidate-title--edit">
        Languages
        {!updateLanguages ?
          <Icon
            icon="edit"
            onClick={() => setUpdateLanguages(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateLanguages(false)}
          />
        }
      </h2>
      <DataLanguageCandidateProfile
        removeIcon={updateLanguages}
        getData={onChangeDeleteChild}
        value={updateUser?.languages}
      />
      {updateLanguages &&
        <FormLanguagesCandidateProfile
          languagesDataSelect={languageSelect}
          getData={onChangeFormGroupChild}
          value={updateUser?.languages}
        />
      }

      <h2 className="form__candidate-title form__candidate-title--edit">
        Experience Details
        {!updateExperience ?
          <Icon
            icon="edit"
            onClick={() => setUpdateExperience(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateExperience(false)}
          />
        }
      </h2>
      <DataExperienceCandidateProfile
        removeIcon={updateExperience}
        getData={onChangeDeleteChild}
        value={updateUser?.experienceDetails}
      />
      {updateExperience &&
        <FormExperienceCandidateProfile
          countriesDataSelect={countrySelect}
          monthData={monthSelect}
          yearData={yearSelect}
          getData={onChangeFormGroupChild}
          value={updateUser?.experienceDetails}
        />
      }
      
      <h2 className="form__candidate-title form__candidate-title--edit">
        Educational Details
        {!updateEducational ?
          <Icon
            icon="edit"
            onClick={() => setUpdateEducational(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateEducational(false)}
          />
        }
      </h2>
      <DataEducationalCandidateProfile
        removeIcon={updateEducational}
        getData={onChangeDeleteChild}
        value={updateUser?.educationalDetails}
      />
      {updateEducational &&
        <FormEducationalCandidateProfile
          countriesDataSelect={countrySelect}
          monthData={monthSelect}
          yearData={yearSelect}
          getData={onChangeFormGroupChild}
          value={updateUser?.educationalDetails}
        />
      }

      <h2 className="form__candidate-title form__candidate-title--edit">
        Certification Details
        {!updateCertification ?
          <Icon
            icon="edit"
            onClick={() => setUpdateCertification(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateCertification(false)}
          />
        }
      </h2>
      <DataCertificationCandidateProfile
        removeIcon={updateCertification}
        getData={onChangeDeleteChild}
        value={updateUser?.certificationDetails}
      />
      {updateCertification &&
        <FormCertificationCandidateProfile
          getData={onChangeFormGroupChild}
          value={updateUser?.certificationDetails}
        />
      }

      <h2 className="form__candidate-title form__candidate-title--edit">
        Address Information
        {!updateAddress ?
          <Icon
            icon="edit"
            onClick={() => setUpdateAddress(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateAddress(false)}
          />
        }
      </h2>
      {updateAddress ?
        <FormAddressInformationCadidateProfile
          countriesDataSelect={countrySelect}
          getData={onChangeFormChild}
          value={updateUser}
        />
      : <DataAddressInformationCandidateProfile />
      }
      
      <h2 className="form__candidate-title form__candidate-title--edit">
        Hiring Detail
        {!updateHiring  ?
          <Icon
            icon="edit"
            onClick={() => setUpdateHiring(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateHiring(false)}
          />
        }
      </h2>
      {updateHiring ?
        <FormHiringDetailCandidateProfile
          countriesDataSelect={countrySelect}
          getData={onChangeFormChild}
          value={updateUser}
        />
      : <DataHiringDetailCandidateProfile />
      }

      {/* <h2 className="form__candidate-title form__candidate-title--edit">Attachment Information
      {!updateAttachment 
      ?<Icon icon="edit"  onClick={() => setUpdateAttachment(true)}/>
      :<Icon icon="close" onClick={() => setUpdateAttachment(false)}/>
      }
      </h2>
      {updateAttachment
      ? <FormAttachmentCandidateProfile getData={onChangeFormChild} value={updateUser} />
      : <DataAttachmentCandidateProfile />
      } */}
      
      <h2 className="form__candidate-title form__candidate-title--edit">
        RGPD
        {!updateRGPD ?
          <Icon
            icon="edit"
            onClick={() => setUpdateRGPD(true)}
          />
        : <Icon
            icon="close"
            onClick={() => setUpdateRGPD(false)}
          />
        }
      </h2>
      {updateRGPD ?
        <FormRgpdCadidateProfile
          getData={onChangeFormChild}
          value={updateUser}
        />
      : <DataRgpdCandidateProfile />
      }

      <div className="form__click">
        <Button
          classNameBtn={`button__${submitDisabled ? 'secondary' : 'primary'}`}
          id="send-profile"
          type="submit"
          text="Submit"
          onClick={(e) => { e.preventDefault(); submitForm()} }
          disabled={submitDisabled}
        />
        <Button
          classNameBtn="button__secondary"
          id="cancel"
          type="button"
          text="Cancel"
          onClick={() => handleBackClick()}
        />
      </div>
      <Error
          message={errorUpdate}
        />
    </form>
  )
}
