import { useContext } from "react";
import UserContext from "../../../context/UserContext";

export const DataAddressInformationCandidateProfile = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <p className="profile__data">
          <span>Cidade:</span>
          {user?.addressInfo?.city}
      </p>
      <p className="profile__data">
          <span>País:</span>
          {user?.addressInfo?.country}
      </p>
    </>
  )
}
