import { ChangeEventHandler, FC, MouseEventHandler } from 'react';

interface InputProps {
  classNameInput?: string;
  id: string;
  label?: string;
  type: string;
  name?: string;
  value?: string;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

export const Input: FC<InputProps> = (props: InputProps) => {
  return (
    <div className="input__group">
      <label className="input__label" htmlFor={props.id}>
        {props.label}
      </label>
      <input className={`input ${props.classNameInput}`}
        type={props.type} 
        id={props.id} 
        name={props.name} 
        value={props.value} 
        required={props.required}
        disabled={props.disabled}
        placeholder={props.placeholder} 
        pattern={props.pattern} 
        onChange={props.onChange}
        onClick={props.onClick}
      />
    </div>
  )
}
