import { useRoutes } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import JobsProvider from '../../context/JobsContext/JobsContext';
import UserProvider from '../../context/UserContext/UserContext';
import CompetencesProvider from '../../context/CompetencesContext/CompetencesContext';
import routes from '../../routes';

export const App = () => {
  const content = useRoutes(routes);
  
  return (
    <UserProvider>
      <JobsProvider>
        <CompetencesProvider>
          <div className='container'>
            <Header />
            {content}
          </div>
          <Footer />
        </CompetencesProvider>
      </JobsProvider>
    </UserProvider>
  );
};
