import { useEffect, useState } from 'react';
import Select from 'react-select'
import Button from '../../../components/Button';
import Input from '../../../components/Input'
import { ICompetency } from '../../../interfaces/ICompetency';
import CreatableSelect from 'react-select/creatable';
import { createCompetences } from '../../../services/competences';

interface Option {
  readonly label: string;
  readonly value: string;
}

export const FormCompetenciesCandidateProfile = (props: {getData: any, value: any, listCompetencies: any}) => {
  const [competencyValue, setCompetencyValue] = useState('');
  const [competencyLabel, setCompetencyLabel] = useState('');
  const [experienceValue, setExperienceValue] = useState('');
  const [totalExperienceValue, setTotalExperienceValue] = useState('');
  const [getObjGroup, setGetObjGroup] = useState<ICompetency[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(props.listCompetencies);
  const [value, setValue] = useState<Option | null>();

  useEffect(() => {
      props.getData(getObjGroup, 'competencies');
  }, [getObjGroup])
  
  
  const addToList = (e: any) => {
    e.preventDefault();
    //FIXME: Corrigir a questão de inserir uma nova competência que não está 100%
    if(competencyValue !== '' || experienceValue !== '' || totalExperienceValue !== '') {
      setGetObjGroup([{
        competency: competencyLabel, 
        competency_id: competencyValue, 
        experience: experienceValue, 
        experienceTotal: totalExperienceValue
      }]);
    }
    setCompetencyValue('');
    setCompetencyLabel('');
    setExperienceValue('');
    setTotalExperienceValue('');
    setValue(null);
  }

  const setCompetencesData = async (e: any) => {
    if(e?.value) {
      setCompetencyValue(e.value);
      setCompetencyLabel(e.label);
      const select = await createOption(e.label, e.value);
      setValue(select)
    } else {
      setCompetencyValue('');
      setCompetencyLabel('');
      setValue(null);
    }
  };

  const createOption = (label: string, value: string) => ({
    label,
    value,
  });
  
  const handleCreate = async (inputValue: string) => {
    setIsLoading(true);
    const create = await createCompetences(inputValue);

    if(create.status == 'success') {
      let value = create.competencies.value;
      let label = create.competencies.label;
      const newOption = await createOption(label, value);
        setOptions((prev: any) => [...prev, newOption]);
      setValue(newOption);
    }

    setIsLoading(false);
  };

  return (   
    <>
      <div className="form__candidate-optional-group">
        <Button  classNameBtn="button__link-primary" id="delete" type="button"   text="Delete" icon=" delete" />
          <div className="select__group">
            <div className="input__label">
              Competency
            </div>
            <CreatableSelect
              className="basic-single"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              isDisabled={isLoading}
              isLoading={isLoading}
              name="Competency"
              options={options}
              onChange={(e) => setCompetencesData(e)}
              onCreateOption={handleCreate}
              value={value}
            />
          </div>
          {/* <Input id="competency" type="text" value={competencyValue} placeholder="" label="Competency" onChange={(e) => setCompetencyValue(e.target.value)} /> */}
          {/* <Input id="experience" type="number" value={experienceValue} placeholder="" label="Experience - Last 3 years(in years)" onChange={(e) => setExperienceValue(e.target.value)} /> */}
          <Input id="total-experience" type="number" value={totalExperienceValue} placeholder="" label="Total Experience(in years)" onChange={(e) => setTotalExperienceValue(e.target.value)} />
      </div>
      <div className="form__candidate-optional">
        <Button  classNameBtn="button__terciary" id="add-competencies" type="button"   text="Add Competencies" icon="add" 
        disabled={competencyValue === '' && experienceValue === '' && totalExperienceValue === ''} onClick={(e) => addToList(e)} />
      </div>
    </> 
  )
}
