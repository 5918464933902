import api from './api';
import { entryPoint } from './api';
import { getToken, login, logout } from './auth';

export const fetchCompetences = async () => {
	const token = await getToken();
	const formCompetencesDetail = new FormData();
	formCompetencesDetail.append('type', 'listCompetences');
	formCompetencesDetail.append('token', token);

	const data = await api.post(`${entryPoint}portalCandidates`, formCompetencesDetail, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		if(response.data.status == 'success') {
			login(response.data.token);
		}
		if(response.data.status == 'error') {
			if(response.data.message == 'Token invalid') {
				logout();
			}
		}
		return response;
	})
	.catch(function (error) {
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});

	return data;
}

export const createCompetences = async (competence) => {
	const token = await getToken();
	const formCompetencesCreate = new FormData();
	formCompetencesCreate.append('type', 'createCompetences');
	formCompetencesCreate.append('token', token);
	formCompetencesCreate.append('name', competence);

	const data = await api.post(`${entryPoint}portalCandidates`, formCompetencesCreate, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		if(response.data.status == 'success') {
			login(response.data.token);
		}
		if(response.data.status == 'error') {
			if(response.data.message == 'Token invalid') {
				logout();
			}
		}
		return response.data;
	})
	.catch(function (error) {
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});

	return data;
}