import React, { useState, useEffect, createContext } from 'react';
import { IListCompetences } from '../../interfaces/IListCompetences';
import { fetchCompetences } from '../../services/competences';
import { logout } from '../../services/auth';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

interface CompetencesContextType {
  competences: IListCompetences | undefined;
  setCompetences: (competences: IListCompetences | undefined) => void;
}

export const CompetencesContext = createContext<CompetencesContextType>({
  competences: undefined,
  setCompetences: () => {},
});

const CompetencesProvider: React.FC<Props> = ({ children }) => {
  const [competencesData, setCompetencesData] = useState<IListCompetences | undefined>(undefined);
  const navigate = useNavigate();
  const is_auth = localStorage.getItem('access_token') !== null;

  useEffect(() => {
    const fetchCompetencesData = async() => {
      if(is_auth) {
        const competences = await fetchCompetences();
        if(competences.data.status === 'error') {
          logout();
          navigate('/', {replace: true});
        } else {
          setCompetencesData(competences!.data.competencies);
        }
      }
    }
    fetchCompetencesData();
  }, [is_auth]);

  const setCompetences = (competences: IListCompetences | undefined) => {
    if (competences) {
      setCompetencesData(competences);
    }
  };
  
  return (
    <CompetencesContext.Provider value={{ competences: competencesData, setCompetences }}>
      {children}
    </CompetencesContext.Provider>
  )
}

export default CompetencesProvider;