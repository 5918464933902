import { useEffect, useState } from 'react';
import FormResetPassword from "../../../containers/FormResetPassword"
import { logout } from '../../../services/auth';

export const ResetPassword = () => {

  useEffect(() => {
    logout();
  }, []);

  return (
    <div>
      <FormResetPassword />
    </div>
  )
}
