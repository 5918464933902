import { useEffect, useState } from 'react';
import { ILanguage } from '../../../interfaces/ILanguage';
import Icon from '../../../components/Icon';

export const DataLanguageCandidateProfile = (props: {removeIcon: any, getData: any, value: any}) => {
  const [getObjGroup, setGetObjGroup] = useState<ILanguage[]>([]);

  useEffect(() => {
    props.getData(getObjGroup, 'languages');
  }, [getObjGroup])
  
  const handleDeleteItem = (index: number) => {
    setGetObjGroup(props.value);
    const newObjGroup = [...props.value];
    newObjGroup.splice(index, 1);
    setGetObjGroup(newObjGroup);
  }

  return (
    <ul className="profile__group-list">
      {props.value?.map((language: ILanguage, index: number) => {
        return (
          <li key={index} className="profile__group-list-item">
            <div className="profile__group-list-item-text">
              <p className="profile__data">{language.language}</p>
              <p className="profile__data">
                <span>Understanding - Listening:</span>
                {language.listening}
              </p>
              <p className="profile__data">
                <span>Understanding - Reading:</span>
                {language.reading}
              </p>
              <p className="profile__data">
                <span>Speaking - Interaction:</span>
                {language.interaction}
              </p>
              <p className="profile__data">
                <span>Speaking - Production:</span>
                {language.production}
              </p>
              <p className="profile__data">
                <span>Writing:</span>
                {language.writing}
              </p>
            </div>
            {props.removeIcon &&
              <Icon icon=" delete"   onClick={() => handleDeleteItem(index)}/>
            }
          </li>
        )
      })}
    </ul>
  )
}
