import React, { useState, useEffect, createContext } from 'react';
import { IJobs } from '../../interfaces/IJobs';
import { fetchJobsList } from '../../services/jobs';
import { isAuthenticated, logout } from '../../services/auth';
import { useNavigate } from 'react-router-dom';


interface Props {
  children: React.ReactNode;
}

interface JobsContextType {
  jobs: IJobs[] | undefined;
  updateJobs: () => void;
}

export const JobsContext = createContext<JobsContextType>({
  jobs: undefined,
  updateJobs: () => {},
});

const JobsProvider: React.FC<Props> = ({ children }) => {
  const [jobsData, setJobsData] = useState<IJobs[] | undefined>(undefined);
  const navigate = useNavigate();
  const is_auth = localStorage.getItem('access_token') !== null;
  const fetchJobsData = async() => {
    if(is_auth) {
      const jobs = await fetchJobsList();
      if(jobs.data.status == 'error') {
        logout();
        navigate('/', {replace: true});
      } else {
        setJobsData(jobs!.data.data);
      }
    }
  }

  useEffect(() => {
    fetchJobsData();
  }, [is_auth]);
  
  const updateJobs = () => {
    fetchJobsData();
  };

  return (
    <JobsContext.Provider value={{ jobs: jobsData, updateJobs }}>
      {children}
    </JobsContext.Provider>
  )
}
  
export default JobsProvider; 