import { IconName, IconPrefix, IconStyle } from '@fortawesome/fontawesome-svg-core';
import { FC, MouseEventHandler } from 'react';
import Icon from '../Icon';

interface ErrorProps {
  message: string;
}

export const Error: FC<ErrorProps> = (props: ErrorProps) => {
  return <h2 className="job__list-item-title">
          {props.message}
        </h2>
}
