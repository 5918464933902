import api from './api';
import { entryPoint } from './api';
import { getToken, login, logout } from './auth';

export const fetchJobsList = async () => {
	const token = await getToken();
	const formListJob = new FormData();
	formListJob.append('type', 'listJobs');
	formListJob.append('token', token);

	const data = api.post(`${entryPoint}portalCandidates`, formListJob, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		if(response.data.status == 'success') {
			login(response.data.token);
		}
		if(response.data.status == 'error') {
			if(response.data.message == 'Token invalid') {
				logout();
			}
		}
		return response;
	})
	.catch(function (error) {
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});

	return data;
}

export const setJobDetailData = async (id, name) => {
	const token = await getToken();
	const formJobDetail = new FormData();
	formJobDetail.append('id_job', id);
	formJobDetail.append('name_job', name);
	formJobDetail.append('type', 'jobDetail');
	formJobDetail.append('token', token);

	const data = await api.post(`${entryPoint}portalCandidates`, formJobDetail, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		if(response.data.status == 'success') {
			login(response.data.token);
		}
		if(response.data.status == 'error') {
			if(response.data.message == 'Token invalid') {
				logout();
			}
		}
		return response.data;
	})
	.catch(function (error) {
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});

	return data;
} 

export const sendCandidateJob = async (candidate, id, name) => {
	const token = await getToken();
	const formJobDetail = new FormData();
	formJobDetail.append('id_candidate', candidate);
	formJobDetail.append('id_job', id);
	formJobDetail.append('name_job', name);
	formJobDetail.append('type', 'candidateJob');
	formJobDetail.append('token', token);

	const data = await api.post(`${entryPoint}portalCandidates`, formJobDetail, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		if(response.data.status == 'success') {
			login(response.data.token);
		}
		if(response.data.status == 'error') {
			if(response.data.message == 'Token invalid') {
				logout();
			}
		}
		return response.data;
	})
	.catch(function (error) {
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});

	return data;
} 
