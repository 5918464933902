import {ChangeEvent, useEffect, useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { ICertification } from '../../../interfaces/ICertification';
import InputFile from "../../../components/InputFile";

export const FormCertificationCandidateProfile = (props: {getData: any, value: any}) => {
  const [nameValue, setNameValue] = useState('');
  const [companyValue, setCompanyValue] = useState('');
  const [certificateCodeValue, setCertificateCodeValue] = useState('');
  const [dateValue, setDateValue] = useState('');
  const [fileName, setFileName] = useState('');
  const [idFileName, setIdFileName] = useState(uuidv4());
  const [fileCertificate, setFileCertificate] = useState<File[]>([]);
  const [getObjGroup, setGetObjGroup] = useState<ICertification[]>([]);

  useEffect(() => {
      props.getData(getObjGroup, 'certificationDetails');
  }, [getObjGroup])

  const handleInputFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if(files !== null) {
      const file = files[0];
      setFileName(file.name);
      setFileCertificate([file]);
    }
  };

  const addToList = (e: any) => {
    e.preventDefault();
    if(nameValue !== '' || companyValue !== '' || dateValue !== '') {
      setGetObjGroup([{
        name: nameValue, 
        company: companyValue,
        certificate_code: certificateCodeValue,
        date: dateValue,
        attachmentCertification: {
          attachement: fileCertificate,
          name: fileName,
        }
      }]);
    }
    setNameValue('');
    setCompanyValue('');
    setCertificateCodeValue('');
    setDateValue('');
    setFileName('');
    setFileCertificate([])
    setIdFileName(uuidv4());
  }
  
  return (
    <>
    <div className="form__candidate-optional-group">
      <Button
        classNameBtn="button__link-primary"
        id="delete"
        type="button"
        text="Delete"
        icon=" delete"
      />
      <Input
        id="name"
        type="text"
        value={nameValue}
        placeholder=""
        label="Name"
        onChange={(e) => setNameValue(e.target.value)}
      />
      <Input
        id="company"
        type="text"
        value={companyValue}
        placeholder=""
        label="Company"
        onChange={(e) => setCompanyValue(e.target.value)}
      />
      <Input
        id="certificate_code"
        type="text"
        value={certificateCodeValue}
        placeholder=""
        label="Certificate Code"
        onChange={(e) => setCertificateCodeValue(e.target.value)}
      />
      <Input
        id="date"
        type="date"
        value={dateValue}
        placeholder=""
        label="Date"
        onChange={(e) => setDateValue(e.target.value)}
      />
      <InputFile
        id={`certificate-file-${idFileName}`}
        name={fileName}
        label="Certificate:"
        accept=".jpg,.jpeg,.png,.pdf"
        onChange={(e) => handleInputFileChange(e)}
      />
      <div className="input__group">
        <p className="form__file">Accepts only <span>.jpg, .jpeg, .png and .pdf</span></p>
      </div>
    </div>
    <div className="form__candidate-optional">
      <Button
        classNameBtn="button__terciary"
        id="add-competencies"
        type="button"
        text="Add Certification"
        icon="add"
        disabled={nameValue === '' && companyValue === '' && dateValue === ''}
        onClick={(e) => addToList(e)}
      />
    </div>
    </>
  )
}
