import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IUser } from "../../interfaces/IUser";
import { setJobDetailData, sendCandidateJob } from "../../services/jobs";
import Button from "../../components/Button";
import Error from '../../components/Error';
import UserContext from "../../context/UserContext";
import { logout } from '../../services/auth';

export const JobDetail = () => {
  const { user, setUser } = useContext(UserContext);
  const searchParams = useParams();
  const navigate = useNavigate();
  const [updateUser, setUpdateUser] = useState<IUser | undefined>(undefined);
  const [jobDetail, setJobDetail] = useState<any>({});
  const [updateMyJobs, setUpdateMyJobs] = useState<{name: string, id: string}>({name: '', id: ''});
  const [apply, setApply] = useState(false);
  const [error, setError] = useState<string>('');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const fetchJobDetailData = async() => {
    return await setJobDetailData(searchParams.jobId, searchParams.jobName);
  }

  const getJobDetailData = async() => {
    return await fetchJobDetailData()
    .then(response => {
      if(response.status == 'error') {
        logout();
        navigate('/', {replace: true});
      } else {
        return setJobDetail(response.data);
      }
    })
  }

  useEffect(() => {
    getJobDetailData();
    if(updateUser === undefined) {
      setUpdateUser(user);
    }
    if(searchParams.jobName && searchParams.jobId) {
      setUpdateMyJobs({
        name: searchParams.jobName, 
        id: searchParams.jobId
      });
      user?.myJobs?.map(myJob => {
        if(searchParams.jobId === myJob.id) {
          setApply(true);
          if(error != 'Thanks for your interest!') {
            setError('You were already interested!')
          }
        }
      });
    }
  }, [user, updateUser]);

  const addJobToMyList = async(e: any) => {
    setSubmitDisabled(true);
    const obj = [...updateUser?.myJobs as {name: string; id: string}[]];
    obj.push(updateMyJobs);
    const update = await sendCandidateJob(user?.id, updateMyJobs.id, updateMyJobs.name);
    if(update.status == 'error') {
      logout();
      navigate('/', {replace: true});
    }
    setError(update.message);
    setUser({...updateUser, myJobs: obj});
    setApply(true);
    setSubmitDisabled(false);
    return {...updateUser, myJobs: obj};
  }

  const handleBackClick = () => {
    if(apply) {
      navigate('/my-jobs-opportunities/');
    } else {
      navigate('/jobs-opportunities/');
    }
  }

  return (
    <>
      {jobDetail &&
        <>
          <div className="title__information">
            <h1 className="title__name">
              {jobDetail.Job_Opening_Name}
              {jobDetail.Job_Id &&
                <span className="title__id">
                  ({jobDetail.Job_Id})
                </span>
              }
            </h1>
          </div>
          <div className="detail__information">
            <h2 className="detail__title">Job Information</h2>
            <p className="detail__information-data">
              <span>Industry</span>
              {jobDetail.Industry}
            </p>
            <p className="detail__information-data">
              <span>{jobDetail.Country}</span>
              {jobDetail.City}
            </p>
            {jobDetail.Remote_Job !== '' &&
            <p className="detail__information-data">
              <span className="material-symbols-outlined">language</span>
              {jobDetail.Remote_Job}
            </p>
            }
          </div>
          <div className="detail__description">
            <h2 className="detail__title">Job Description</h2>
            <p className="detail__description-data">
              {jobDetail.Description}
            </p>
          </div>
        </>
      }
      <div className="form__click">
        {apply
          ? <Error
              message={error}
            />
          : <Button
              classNameBtn={`button__${submitDisabled ? 'secondary' : 'primary'}`}
              id="send-profile"
              type="submit"
              text="Candidatar"
              onClick={(e) => { e.preventDefault(); addJobToMyList(e)} }
              disabled={submitDisabled}
            />
        }
        <Button
          classNameBtn="button__secondary"
          id="cancel"
          type="button"
          text="Voltar"
          onClick={() => handleBackClick()}
        />
      </div>
    </>
  )
}
