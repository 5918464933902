import { useEffect, useState } from 'react';
import { IExperience } from '../../../interfaces/IExperience';
import Icon from '../../../components/Icon';

export const DataExperienceCandidateProfile = (props: {removeIcon: any, getData: any, value: any}) => {
  const [getObjGroup, setGetObjGroup] = useState<IExperience[]>([]);

  useEffect(() => {
    props.getData(getObjGroup, 'experienceDetails');
  }, [getObjGroup])
  
  const handleDeleteItem = (index: number) => {
    setGetObjGroup(props.value);
    const newObjGroup = [...props.value];
    newObjGroup.splice(index, 1);
    setGetObjGroup(newObjGroup);
  }

  return (
    <ul className="profile__group-list">
      {props.value?.map((experience: IExperience, index: number) => {
        return (
          <li key={index} className="profile__group-list-item">
            <div className="profile__group-list-item-text">
              <p className="profile__data">{experience.occupation}</p>
              <p className="profile__data">{experience.company}</p>
              <p className="profile__data">
                <span>Summary:</span>
                {experience.summary}
              </p>
              <p className="profile__data">
                <span>Work Duration:</span>
                {experience.month}/{experience.year} to {experience.monthTo}/{experience.yearTo}
              </p>
              <p className="profile__data">
                <span>City:</span>
                {experience.city}
              </p>
              <p className="profile__data">
                <span>Country:</span>
                {experience.country}
              </p>
              <p className="profile__data">
                <span>I currently work here:</span>
                {experience.workHere ? 'Yes' : 'No'}
              </p>
            </div>
            {props.removeIcon &&
              <Icon icon=" delete"   onClick={() => handleDeleteItem(index)}/>
            }
          </li>
        )
      })}
    </ul>
  )
}
