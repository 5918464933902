import type { RouteObject } from 'react-router';

import Login from '../pages/Login';
import ResetPassword from '../pages/Login/ResetPassword';
import JobOpps from '../pages/JobOpps';
import JobOppsDetail from '../pages/JobOppsDetail';
import CandidateProfile from '../pages/CandidateProfile';
import CandidateJobOpps from '../pages/CandidateJobOpps';
import FirstAccess from '../pages/Login/FirstAccess';

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/jobs-opportunities",
    element: <JobOpps />,
  },
  {
    path: "/jobs-opportunities/:jobId/:jobName",
    element: <JobOppsDetail />,
  },
  {
    path: "/profile",
    element: <CandidateProfile />,
  },
  {
    path: "/my-jobs-opportunities",
    element: <CandidateJobOpps />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/first-access",
    element: <FirstAccess />,
  },
];

export default routes;
