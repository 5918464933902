import { useContext } from 'react';
import UserContext from '../../../context/UserContext';

export const DataHiringDetailCandidateProfile = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <p className="profile__data">
        <span>Intended Position:</span>
        {user?.hiringDetail?.intendedPosition}
      </p>
      <p className="profile__data">
        <span>Net Salary Expectation:</span>
        {user?.hiringDetail?.netSalary}/day
      </p>
      <p className="profile__data">
        <span>Availability In loco:</span>
        {user?.hiringDetail?.availabilityInLoco} days
      </p>
      <p className="profile__data">
        <span>Availability Remote:</span>
        {user?.hiringDetail?.availabilityRemote} days
      </p>
      <p className="profile__data">
        <span>Passport:</span>
        {user?.hiringDetail?.passport}
      </p>
      <p className="profile__data">
        <span>Degree Documentation:</span>
        {user?.hiringDetail?.degreeDocumentation}
      </p>
      <p className="profile__data">
        <span>Reference:</span>
        {user?.hiringDetail?.reference}
      </p>
    </>
  )
}
