import { MouseEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Error from '../../components/Error';
import Input from '../../components/Input'
import { loginUser, isAuthenticated } from '../../services/auth';

export const FormLogin = () => {
  const navigate = useNavigate();

  const [valueLogin, setValueLogin] = useState('');
  const [valuePassword, setValuePassword] = useState('');
  const [error, setError] = useState('');

  const handleFormBtn = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const login = await loginUser(valueLogin, valuePassword);
    if(login.status == 'success') {
      navigate('/profile/');
    }
    if(login.status == 'error') {
      setError(login.message);
    }
  }

  const handleBackBtn = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    window.location.replace('https://scorpioncircle.com/');
  }

  return (
    <>
      <form className="form__login">
        <Input
          label="Login"
          id="login" 
          type="text" 
          value={valueLogin} 
          placeholder="login@login.com.br"
          onChange={(e) => setValueLogin(e.target.value)}         
        />
        <Input
          label="Senha" 
          id="password" 
          type="password" 
          value={valuePassword} 
          placeholder="senha de no mínimo 6 caracteres"
          onChange={(e) => setValuePassword(e.target.value)}         
        />
        <Error
          message={error}
        />
        <div className="form__click">
          <Button  
            classNameBtn="button__primary"
            id="login-btn"
            type="submit"
            text="Entrar"
            onClick={(e) => handleFormBtn(e)} 
          />
          <Button  
            classNameBtn="button__secondary"
            id="cancel-btn" 
            type="button" 
            text="Voltar" 
            onClick={(e) => handleBackBtn(e)}    
          />
        </div>
        <Link to="https://scorpioncircle.com/candidate/" className="form__click-register">
          Cadastre-se
        </Link>
        <Link to="/reset-password" className="form__click-forgot-password">
          Esqueci a senha
        </Link>
        <Link to="/first-access" className="form__click-first-access">
          Primeiro acesso
        </Link>
      </form>
    </>
  )
}
