import { IconName, IconPrefix, IconStyle } from '@fortawesome/fontawesome-svg-core';
import { FC, MouseEventHandler } from 'react';
import Icon from '../Icon';

interface ButtonProps {
  classNameBtn: string;
  id: string;
  type: "button" | "submit" | "reset" | undefined;
  text: string;
  icon?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

export const Button: FC<ButtonProps> = (props: ButtonProps) => {
  return <button 
            id={props.id} 
            type={props.type} 
            className={`button ${props.classNameBtn} ${props.icon && "button__icon"}`} 
            disabled={props.disabled}
            onClick={props.onClick}>
            {props.text}
            {props.icon &&
              <Icon icon={props.icon}/>
            }
          </button>
}
