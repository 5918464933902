import { v4 as uuidv4 } from 'uuid';
import api from './api';
import { entryPoint } from './api';
import { getToken, login, logout } from './auth';

export const fetchUser = async () => {
	const token = getToken();
	const formUserDetail = new FormData();
	formUserDetail.append('type', 'detailUser');
	formUserDetail.append('token', token);

	return await api.post(`${entryPoint}portalCandidates`, formUserDetail, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		if(response.data.status === 'success') {
			login(response.data.token);
		}
		if(response.data.status === 'error') {
			if(response.data.message === 'Token invalid') {
				logout();
			}
		}
		return response;
	})
	.catch(function (error) {
		console.log(error);
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});
}

export const sendUpdateUser = async (candidate) => {
	const token = getToken();
	const formUserUpdate = new FormData();
	formUserUpdate.append('type', 'updateUser');
	formUserUpdate.append('token', token);
	for (let key in candidate) {
		if (candidate.hasOwnProperty(key)) {
			if (Array.isArray(candidate[key])) {
				candidate[key].forEach((item, index) => {
					for (let itemKey in item) {
						if (item.hasOwnProperty(itemKey)) {
							let is_attachment = itemKey === 'attachmentCertification' || itemKey === 'attachmentEducational';
							if (is_attachment && item[itemKey].attachement && item[itemKey].attachement.length > 0) {
								let id_temp = uuidv4();
								formUserUpdate.append(`${key}[${index}][${itemKey}][${id_temp}]`, item[itemKey].attachement[0]);
								formUserUpdate.append(`${key}[${index}][${itemKey}]`, id_temp);
							} else {
								formUserUpdate.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
							}
						}
					}
				});
			} else if (typeof candidate[key] === 'object') {
				for (let subKey in candidate[key]) {
					if (candidate[key].hasOwnProperty(subKey)) {
						formUserUpdate.append(`${key}[${subKey}]`, candidate[key][subKey]);
					}
				}
			} else {
				formUserUpdate.append(key, candidate[key]);
			}
		}
	}
	return await api.post(`${entryPoint}portalCandidates`, formUserUpdate, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
		.then(function (response) {
			if (response.data.status === 'success') {
				login(response.data.token);
			}
			if (response.data.status === 'error') {
				if (response.data.message === 'Token invalid') {
					logout();
				}
			}
			return response.data;
		})
		.catch(function (error) {
			console.log(error);
			throw new Error("Serviço indisponível, tente novamente mais tarde")
		});
}