import { getMonthList } from "month-list";

export const monthsData = (): {}[] => {
  return getMonthList('en', 'short').map((month) => {
    return {label: month, value: month};
  })
}

const years = require('ye-ars');
const today = new Date();
const initialYear = 1950;
const options = {
  initialYear: initialYear,
  count: (today.getFullYear() - initialYear) + 1
}

export const yearsData = (): {}[] => {
  const sortYears = years(options).sort((a: number, b: number) => b - a)
  return sortYears.map((year: string) => {
    return {label: year, value: year};
  })
}