import { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";

export const DataBasicInfoCandidateProfile = () => {
  const { user } = useContext(UserContext);
  const [newDate, setNewDate] = useState('');

  useEffect(() => {
    const splitDate = (user?.basicInfo?.birth)?.split('-');
    if(splitDate) {
      setNewDate(`${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`)
    }
  }, [user?.basicInfo?.birth])
  

  return (
  <>
    <p className="profile__data">
      <span>Name:</span>
      {user?.basicInfo?.firstName} {user?.basicInfo?.lastName}
    </p>
    <p className="profile__data">
      <span>E-mail:</span>
      {user?.basicInfo?.email}
    </p>
    <p className="profile__data">
      <span>Birth:</span>
      {newDate}
    </p>
    <p className="profile__data">
      <span>Mobile:</span>
      {user?.basicInfo?.mobile}
    </p>
    <p className="profile__data">
      <span>LinkedIn:</span>
      {user?.basicInfo?.linkedin}
    </p>
    <p className="profile__data">
      <span>Birth Country:</span>
      {user?.basicInfo?.birthCountry}
    </p>
    <p className="profile__data">
      <span>Citizenship Country:</span>
      {user?.basicInfo?.citizenshipCountry}
    </p>
    <p className="profile__data">
      <span>Gender:</span>
      {user?.basicInfo?.gender}
    </p>
    <p className="profile__data">
      <span>Marital:</span>
      {user?.basicInfo?.marital}
    </p>
  </>
  )
}
