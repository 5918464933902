import { MouseEventHandler } from "react";

interface IconProps {
  classNameIcon?: string;
  id?: string;
  icon: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}
export const Icon = (props: IconProps) => {
  return (
    <span className={`icon material-symbols-outlined ${props.classNameIcon}`} onClick={props.onClick}>{props.icon}</span>
  )
}
