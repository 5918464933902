import React, { ChangeEventHandler, FC } from 'react'

interface InputFileProps {
  id: string;
  label?: string;
  name?: string;
  value?: string;
  accept?: string
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const InputFile: FC<InputFileProps> = (props: InputFileProps) => {
  return (
    <div className="input__group" key={props.id}>
      <label className="input__label">
        {props.label}
      </label>
      <p>{props.name}</p>
      <input className="input__file"
        type="file"
        id={props.id}
        key={props.id}
        name={props.name}
        value={props.value} 
        accept={props.accept}
        onChange={props.onChange}
      />
    </div>
  )
}
