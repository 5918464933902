import React, { ChangeEventHandler } from 'react'

interface CheckboxProps {
  id: string;
  label: string;
  name?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Checkbox = (props: CheckboxProps) => {
  return (
    <div className="checkbox__group">
      <input className="checkbox"
        type="checkbox" 
        id={props.id} 
        name={props.name} 
        value={props.value} 
        required={props.required}
        disabled={props.disabled}
        checked= {props.checked}
        onChange={props.onChange}
      />
      <label className="checkbox__label" htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  )
}
