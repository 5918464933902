import React, { useState, useEffect, createContext } from 'react';
import { IUser } from '../../interfaces/IUser';
import { fetchUser } from '../../services/user';
import { isAuthenticated, logout } from '../../services/auth';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

interface UserContextType {
  user: IUser | undefined;
  setUser: (user: IUser | undefined) => void;
}

export const UserContext = createContext<UserContextType>({
  user: undefined,
  setUser: () => {},
});

const UserProvider: React.FC<Props> = ({ children }) => {
  const [userData, setUserData] = useState<IUser | undefined>(undefined);
  const navigate = useNavigate();
  const is_auth = localStorage.getItem('access_token') !== null;

  useEffect(() => {
    const fetchUserData = async() => {
      if(is_auth) {
        const user = await fetchUser();
        if(user.data.status == 'error') {
          logout();
          navigate('/', {replace: true});
        } else {
          setUserData(user!.data.candidate);
        }
      }
    }
    fetchUserData();
  }, [is_auth]);

  const setUser = (user: IUser | undefined) => {
    if (user) {
      setUserData(user);
    }
  };
  
  return (
    <UserContext.Provider value={{ user: userData, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;