import JobDetail from "../../containers/JobDetail"


export const JobOppsDetail = () => {
  return (
    <section>
      <JobDetail />
    </section>
  )
}
