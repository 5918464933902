import Input from '../../../components/Input'
import Select from '../../../components/Select';

export const FormAddressInformationCadidateProfile = (props: {countriesDataSelect: {label: string, value: string}[], getData: any, value: any}) => {

  return (
    <div className="form__candidate-optional">
      <Input id="city" type="text" value={props.value.addressInfo.city} placeholder="" label="City" required={true} name="city" onChange={(e) => props.getData(e, "addressInfo")} />
      <Select 
        label="Country"
        id="country" 
        value={props.value.addressInfo.country} 
        name="country"
        options={props.countriesDataSelect} 
        onChange={(e) => props.getData(e, "addressInfo")}
      /> 
    </div>
  )
}
