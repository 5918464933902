import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IJobs } from '../../interfaces/IJobs';
import JobListItem from "../../components/JobListItem";
import JobsContext from "../../context/JobsContext";
import UserContext from "../../context/UserContext";
import { IUser } from "../../interfaces/IUser";

export const JobListCandidate = () => {
  const { user } = useContext(UserContext);
  const { jobs } = useContext(JobsContext);

  const [jobsRes, setJobsRes] = useState<IJobs[] | undefined>(undefined);
  const [userRes, setUserRes] = useState<IUser | undefined>(undefined);
  const [filterJobs, setFilterJobs] = useState<IJobs[] | undefined>(undefined);  

  const navigate = useNavigate();

  useEffect(() => {
    setUserRes(user);
    setJobsRes(jobs);

    const getMyIds = userRes?.myJobs?.map(myJob => {
      return myJob;
    });
    const getFilter = jobsRes?.filter(job => {
      const match = getMyIds?.find(myJob => myJob.id === job.id);
      if (match) {
        job.Status = match.status;
        return { ...job };
      }
    });
    setFilterJobs(getFilter);
  }, [user, jobs, jobsRes]);

  const handleLinkClick = (id: string, name: string): void => {
    navigate(`/jobs-opportunities/${id}/${name}`);
  }
  
  return <ul className="job__list">
          {filterJobs && filterJobs?.map((job: any) => <JobListItem 
            key={job.id}
            Industry={job.Industry}
            Job_Type={job.Job_Type} 
            Remote_Job={job.Remote_Job} 
            Job_Opening_Name={job.Job_Opening_Name} 
            Country={job.Country} 
            $url={job.$url} 
            id={job.id} 
            City={job.City}
            Status={job.Status}
            onClick={() => handleLinkClick(job.id, job.Job_Opening_Name)}/>
          )} 
          </ul>
} 