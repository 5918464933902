import { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/UserContext";

export const DataRgpdCandidateProfile = () => {
  const { user } = useContext(UserContext);

  return (
    <div>
      <p className="profile__data">
        <span>Contact By E-mail:</span>
        {user?.rgpd?.contactByEmail ? 'Yes' : 'No'}
      </p>
      <p className="profile__data">
        <span>Contact by WhatsApp:</span>
        {user?.rgpd?.contactByWhatsapp ? 'Yes' : 'No'}
      </p>
    </div>
  )
}
