import { useEffect, useState } from 'react';
import { ICompetency } from '../../../interfaces/ICompetency';
import Icon from '../../../components/Icon';

export const DataCompetenciesCandidateProfile = (props: {removeIcon: any, getData: any, value: any}) => {
  const [getObjGroup, setGetObjGroup] = useState<ICompetency[]>([]);

  useEffect(() => {
    props.getData(getObjGroup, 'competencies');
  }, [getObjGroup])
  
  const handleDeleteItem = (index: number) => {
    setGetObjGroup(props.value);
    const newObjGroup = [...props.value];
    newObjGroup.splice(index, 1);
    setGetObjGroup(newObjGroup);
  }
  
  return (
    <ul className="profile__group-list">
      {props.value?.map((competency: ICompetency, index: number) => {
        return (
          <li key={index} className="profile__group-list-item">
            <div className="profile__group-list-item-text">
              <p className="profile__data">{competency.competency}</p>
              <p className="profile__data">
                <span>Experience - Last 3 years:</span>
                {competency.experience} years
              </p>
              <p className="profile__data">
                <span>Total Experience:</span>
                {competency.experienceTotal} years
              </p>
            </div>
            {props.removeIcon &&
              <Icon icon=" delete"   onClick={() => handleDeleteItem(index)}/>
            }
          </li>
        )
      })}
    </ul>
  )
}
