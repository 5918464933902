import { useEffect, useState } from 'react';
import FormFirstAccess from "../../../containers/FormFirstAccess"
import { logout } from '../../../services/auth';

export const FirstAccess = () => {

  useEffect(() => {
    logout();
  }, []);

  return (
    <div>
      <FormFirstAccess />
    </div>
  )
}
