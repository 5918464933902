import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IJobs } from '../../interfaces/IJobs';
import JobListItem from "../../components/JobListItem";
import JobsContext from "../../context/JobsContext";

export const JobList = () => {
  const { jobs } = useContext(JobsContext);

  const [jobsRes, setJobsRes] = useState<IJobs[] | undefined>(undefined);
  const [getJobId, setGetJobId] = useState('');
  const [getJobName, setGetJobName] = useState('');
  
  const navigate = useNavigate();

  useEffect(() => {
    setJobsRes(jobs);
  }, [jobs]);

  const handleLinkClick = (id: string, name: string): void => {
    setGetJobId(id);
    setGetJobName(encodeURIComponent(name));
    navigate(`/jobs-opportunities/${id}/${encodeURIComponent(name)}`);
  }

  return <ul className="job__list">
            {jobsRes && jobsRes.map((job: any) => <JobListItem 
              key={job.id}
              Industry={job.Industry}
              Job_Type={job.Job_Type} 
              Remote_Job={job.Remote_Job} 
              Job_Opening_Name={job.Job_Opening_Name} 
              Country={job.Country} 
              $url={job.$url} 
              id={job.id} 
              City={job.City}
              Status=""
              onClick={() => handleLinkClick(job.id, job.Job_Opening_Name)}/>
            )} 
          </ul>
}
