import { useEffect, useState } from 'react';
import FormLogin from "../../containers/FormLogin"
import { logout } from '../../services/auth';

export const Login = () => {

  useEffect(() => {
    logout();
  }, []);

  return (
    <div>
      <FormLogin />
    </div>
  )
}
