import { useEffect, useState } from 'react';
import { IEducational } from '../../../interfaces/IEducational';
import Icon from '../../../components/Icon';

export const DataEducationalCandidateProfile = (props: {removeIcon: any, getData: any, value: any}) => {
  const [getObjGroup, setGetObjGroup] = useState<IEducational[]>([]);

  useEffect(() => {
    props.getData(getObjGroup, 'educationalDetails');
  }, [getObjGroup])
  
  const handleDeleteItem = (index: number) => {
    setGetObjGroup(props.value);
    const newObjGroup = [...props.value];
    newObjGroup.splice(index, 1);
    setGetObjGroup(newObjGroup);
  }

  return (
    <ul className="profile__group-list">
      {props.value?.map((educational: IEducational, index: number) => {
        return (
          <li key={index} className="profile__group-list-item">
            <div className="profile__group-list-item-text">
              <p className="profile__data">{educational.institute}</p>
              <p className="profile__data">
                <span>Degree name:</span>
                {educational.degree}
              </p>
              <p className="profile__data">
                <span>Duration:</span>
                {educational.month}/{educational.year} to {educational.monthTo}/{educational.yearTo}
              </p>
              <p className="profile__data">
                <span>Currently pursuing:</span>
                {educational.pursuing ? 'Yes' : 'No'}
              </p>
            </div>
            {props.removeIcon &&
              <Icon icon=" delete"   onClick={() => handleDeleteItem(index)}/>
            }
          </li>
        )
      })}
    </ul>
  )
}
