import {ChangeEvent, useEffect, useState} from 'react'
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { IEducational } from '../../../interfaces/IEducational';
import InputFile from "../../../components/InputFile";

export const FormEducationalCandidateProfile = (
  props: {
    countriesDataSelect: {label: string, value: string}[],
    monthData: {label: string, value: string}[],
    yearData: {label: string, value: string}[]
    getData: any, value: any
  }
) => {

  const [instituteValue, setInstituteValue] = useState('');
  const [degreeValue, setDegreeValue] = useState('');
  const [monthValue, setMonthValue] = useState('Month');
  const [yearValue, setYearValue] = useState('Year');
  const [monthToValue, setMonthToValue] = useState('Month');
  const [yearToValue, setYearToValue] = useState('Year');
  const [pursuingValue, setPursuingValue] = useState(false);
  const [fileName, setFileName] = useState('');
  const [idFileName, setIdFileName] = useState(uuidv4());
  const [fileEducational, setFileEducational] = useState<File[]>([]);
  const [getObjGroup, setGetObjGroup] = useState<IEducational[]>([]);

  useEffect(() => {
      props.getData(getObjGroup, 'educationalDetails');
  }, [getObjGroup]);

  const handleInputFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if(files !== null) {
      const file = files[0];
      setFileName(`${file.name}`);
      setFileEducational([file]);
    }
  };

  const addToList = (e: any) => {
    e.preventDefault();
    if(instituteValue !== '' || degreeValue !== '' || monthValue !== 'Month' || yearValue !== 'Year' ||
    monthToValue !== 'Month' || yearToValue !== 'Year') {
      setGetObjGroup([{
        institute: instituteValue, 
        degree: degreeValue, 
        month: monthValue,
        year: yearValue,
        monthTo: monthToValue,
        yearTo: yearToValue,
        pursuing: pursuingValue,
        attachmentEducational: {
          attachement: fileEducational,
          name: fileName,
        }
      }]);
    }
    setInstituteValue('');
    setDegreeValue('');
    setMonthValue('Month');
    setYearValue('Year');
    setMonthToValue('Month');
    setYearToValue('Year');
    setPursuingValue(false);
    setFileName('');
    setFileEducational([]);
    setIdFileName(uuidv4());
  }

  return (
    <>
    <div className="form__candidate-optional-group">
    <Button
      classNameBtn="button__link-primary"
      id="delete"
      type="button"
      text="Delete"
      icon="delete"
    />
    <Input
      id="institute"
      type="text"
      value={instituteValue}
      placeholder=""
      label="Institute/School"
      onChange={(e) => setInstituteValue(e.target.value)}
    />
    <Input
      id="degree"
      type="text"
      value={degreeValue}
      placeholder=""
      label="Degree"
      onChange={(e) => setDegreeValue(e.target.value)}
    />
    <div className="form__candidate-optional-group--date">
      <div className="form__candidate-optional-group--date-align">
        <label className="input__label">Work Duration</label>
        <div className="form__candidate-optional-group--date-select">
        <Select 
          id="month" 
          value={monthValue} 
          name="month"
          options={props.monthData} 
          onChange={(e) => setMonthValue(e.target.value)}
        />
        <Select 
          id="year" 
          value={yearValue} 
          name="year"
          options={props.yearData} 
          onChange={(e) => setYearValue(e.target.value)}
        />
        </div>
      </div>
      <div className="form__candidate-optional-group--date-align">
        <label className="input__label">To</label>
        <div className="form__candidate-optional-group--date-select">
        <Select 
          id="month-to" 
          value={monthToValue} 
          name="monthTo"
          options={props.monthData} 
          onChange={(e) => setMonthToValue(e.target.value)}
        />
        <Select 
          id="year-to" 
          value={yearToValue} 
          name="yearTo"
          options={props.yearData} 
          onChange={(e) => setYearToValue(e.target.value)}
        />
        </div>
      </div>
    </div>
    <Checkbox
      id="pursuing"
      checked={pursuingValue}
      label="Currently pursuing"
      onChange={(e) => setPursuingValue(e.target.checked)}
    />
    <InputFile
        id={`certificate-file-${idFileName}`}
        name={fileName}
        label="Certificate:"
        accept=".doc,.docx,.pdf"
        onChange={(e) => handleInputFileChange(e)}
    />
    <div className="input__group">
      <p className="form__file">Accepts only <span>.doc, .docx and .pdf</span></p>
    </div>
  </div>
  <div className="form__candidate-optional">
    <Button
      classNameBtn="button__terciary"
      id="add-educational"
      type="button"
      text="Add Educational Details"
      icon="add"
      disabled={
        instituteValue === '' &&
        degreeValue === '' &&
        monthValue === 'Month' &&
        yearValue === 'Year' &&
        monthToValue === 'Month'&&
        yearToValue === 'Year' &&
        !pursuingValue
      }
      onClick={(e) => addToList(e)}
    />
  </div>
  </>
  )
}
