import { FC, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';

interface jobListItemProps {
  Industry: string;
  Job_Type: string;
  Remote_Job: string;
  Job_Opening_Name: string;
  Country: string;
  $url: string;
  id: string;
  City: string;
  Status: string;
  onClick?: MouseEventHandler<HTMLLIElement> | undefined;
}

export const JobListItem: FC<jobListItemProps> = (props: jobListItemProps) => {
  return <li className="job__list-item" onClick={props.onClick}>
          <h2 className="job__list-item-title">{props.Job_Opening_Name}</h2>
          <p className="job__list-item-text">
            <span className="job__list-item-span">Cidade:</span> 
            <span>{props.City}</span>
          </p>
          <p className="job__list-item-text">
            <span className="job__list-item-span">País:</span> 
            <span>{props.Country}</span>
          </p>
          {props.Job_Type !== '' &&
            <p className="job__list-item-text">
              <span className="job__list-item-span">Tipo de Emprego:</span> 
              <span>{props.Job_Type}</span>
            </p>
          }
          {props.Remote_Job !== '' &&
            <p className="job__list-item-text">
              <span className="job__list-item-span">Trabalho Remoto:</span> 
              <span>{props.Remote_Job}</span>
            </p>
          }
          {props.Status !== '' &&
            <p className="job__list-item-text">
              <span className="job__list-item-span"><strong>Status:</strong></span> 
              <span><strong>{props.Status}</strong></span>
            </p>
          }
        </li>
}
