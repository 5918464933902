import { useEffect, useState } from 'react'
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { IExperience } from '../../../interfaces/IExperience';

export const FormExperienceCandidateProfile = (
  props: {countriesDataSelect: {label: string, value: string}[], 
  monthData: {label: string, value: string}[],
  yearData: {label: string, value: string}[],
  getData: any, value: any}) => {
  const [occupationValue, setOccupationValue] = useState('');
  const [companyValue, setCompanyValue] = useState('');
  const [summaryValue, setSummaryValue] = useState('');
  const [monthValue, setMonthValue] = useState('Month');
  const [yearValue, setYearValue] = useState('Year');
  const [monthToValue, setMonthToValue] = useState('Month');
  const [yearToValue, setYearToValue] = useState('Year');
  const [cityValue, setCityValue] = useState('');
  const [countryValue, setCountryValue] = useState('None');
  const [workHereValue, setWorkHereValue] = useState(false);
  const [getObjGroup, setGetObjGroup] = useState<IExperience[]>([]);

  useEffect(() => { 
    props.getData(getObjGroup, 'experienceDetails');
  }, [getObjGroup]);

  const addToList = (e: any) => {
    e.preventDefault();
    if(occupationValue !== '' || companyValue !== '' || summaryValue !== '' || monthValue !== 'Month' || 
    yearValue !== 'Year' || monthToValue !== 'Month' || yearToValue !== 'Year' || cityValue !== '' || countryValue !== 'None') {
      setGetObjGroup([{
        occupation: occupationValue, 
        company: companyValue, 
        summary: summaryValue,
        month: monthValue,
        year: yearValue,
        monthTo: monthToValue,
        yearTo: yearToValue,
        city: cityValue,
        country: countryValue,
        workHere: workHereValue
      }]);
    }
    setOccupationValue('');
    setCompanyValue('');
    setSummaryValue('');
    setMonthValue('Month');
    setYearValue('Year');
    setMonthToValue('Month');
    setYearToValue('Year');
    setCityValue('');
    setCountryValue('None');
    setWorkHereValue(false);
  }
  
  return (
    <>
    <div className="form__candidate-optional-group">
    <Button  classNameBtn="button__link-primary" id="delete" type="button"   text="Delete" icon=" delete" />
    <Input id="occupation" type="text" value={occupationValue} placeholder="" label="Occupation/Title" onChange={(e) => setOccupationValue(e.target.value)} />
    <Input id="company" type="text" value={companyValue} placeholder="" label="Company" onChange={(e) => setCompanyValue(e.target.value)} />
    <div className="input__group">
      <label htmlFor="summary" className="input__label">Summary</label>
      <textarea className="input" id="summary" value={summaryValue} onChange={(e) => setSummaryValue(e.target.value)}></textarea>
    </div>
    <div className="form__candidate-optional-group--date">
      <div className="form__candidate-optional-group--date-align">
          <label className="input__label">Work Duration</label>
          <div className="form__candidate-optional-group--date-select">
          <Select 
            id="month" 
            value={monthValue} 
            options={props.monthData} 
            onChange={(e) => setMonthValue(e.target.value)}
          />
          <Select 
            id="year" 
            value={yearValue} 
            options={props.yearData} 
            onChange={(e) => setYearValue(e.target.value)}
          />
        </div>
      </div>
      <div className="form__candidate-optional-group--date-align">
        <label className="input__label">To</label>
        <div className="form__candidate-optional-group--date-select">
        <Select 
          id="month-to" 
          value={monthToValue} 
          options={props.monthData} 
          onChange={(e) => setMonthToValue(e.target.value)}
        />
        <Select 
          id="year-to" 
          value={yearToValue} 
          options={props.yearData} 
          onChange={(e) => setYearToValue(e.target.value)}
        />
        </div>
      </div>
    </div>
    <Input id="city" type="text" value={cityValue} placeholder="" label="City" onChange={(e) => setCityValue(e.target.value)} />
    <Select 
      label="Country"
      id="country" 
      value={countryValue} 
      options={props.countriesDataSelect} 
      onChange={(e) => setCountryValue(e.target.value)}
    />
    <Checkbox id="work-here" checked={workHereValue} label="I current work here" onChange={(e) => setWorkHereValue(e.target.checked)} />
  </div>
  <div className="form__candidate-optional">
    <Button  classNameBtn="button__terciary" id="add-experience" type="button"   text="Add Experience Details" icon="add"
    disabled={occupationValue === '' && companyValue === '' && summaryValue === '' &&
    monthValue === 'Month' && yearValue === 'Year' && monthToValue === 'Month' && yearToValue === 'Year' &&
    cityValue === '' && countryValue === 'None' && !workHereValue} onClick={(e) => addToList(e)} />
  </div>
  </>
  )
}
