import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import Logo from '../Logo';
import { isAuthenticated, logout } from '../../services/auth';


export const Header = () => {
  const [open, setOpen] = useState(false);
  const is_auth = localStorage.getItem('access_token') !== null;

  useEffect(() => {
    if (open) {
      document.body.style.position = 'fixed';
    } else {
      document.body.style.position = 'unset';
    }
  }, [open, is_auth]);

  return (
    <header className="header" id={is_auth.toString()}>
      <Logo />
      <nav className="header__nav-mobile">
        <div className={`header__nav-mobile-container ${open && "active"}`}>
          { isAuthenticated() &&
            <ul className="header__nav-mobile-list">
              <li className="header__nav-mobile-list-item">
                <Link to={"/jobs-opportunities"} onClick={() => setOpen(false)}>Vagas</Link>
              </li>
              <li className="header__nav-mobile-list-item">
                <Link to={"/my-jobs-opportunities"} onClick={() => setOpen(false)}>Minhas vagas</Link>
              </li>
              <li className="header__nav-mobile-list-item">
                <Link to={"/profile"} onClick={() => setOpen(false)}>Meu perfil</Link>
              </li>
              <li className="header__nav-mobile-list-item">
                <Link to={"/"} onClick={() => logout()}>Sair</Link>
              </li>
            </ul>
          }
          <Icon icon="close" onClick={() => setOpen(false)}/>
        </div>
        <Icon icon="menu" onClick={() => setOpen(true)}/>
      </nav>
      <nav className="header__nav">
        { isAuthenticated() &&
          <ul className="header__nav-list">
            <li className="header__nav-list-item">
              <Link to={"/jobs-opportunities"} onClick={() => setOpen(false)}>Vagas</Link>
            </li>
            <li className="header__nav-list-item">
              <Link to={"/my-jobs-opportunities"} onClick={() => setOpen(false)}>Minhas vagas</Link>
            </li>
            <li className="header__nav-list-item">
              <Link to={"/profile"} onClick={() => setOpen(false)}>Meu perfil</Link>
            </li>
            <li className="header__nav-list-item">
              <Link to={"/"} onClick={() => logout()}>Sair</Link>
            </li>
          </ul>
        }
      </nav>
    </header>
  )
}
