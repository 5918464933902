import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ILanguage } from '../../../interfaces/ILanguage';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

const levelSelect = [
  { label: 'None', value: 'none' },
  { label: 'A1', value: 'A1' },
  { label: 'A2', value: 'A2' },
  { label: 'B1', value: 'B1' },
  { label: 'B2', value: 'B2' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
];

export const FormLanguagesCandidateProfile = (props: {getData: any, value: any, languagesDataSelect: {label: string, value: string}[]}) => {
  const [languageValue, setLanguageValue] = useState('');
  const [listeningValue, setListeningValue] = useState('');
  const [readingValue, setReadingValue] = useState('');
  const [interactionValue, setInteractionValue] = useState('');
  const [productionValue, setProductionValue] = useState('');
  const [writingValue, setWritingValue] = useState('');
  const [getObjGroup, setGetObjGroup] = useState<ILanguage[]>([]);

  useEffect(() => { 
    props.getData(getObjGroup, 'languages');
  }, [getObjGroup]);

  const addToList = (e: any) => {
    e.preventDefault();
    if(languageValue !== 'None' && listeningValue !== 'None' && readingValue !== 'None' &&
    interactionValue !== 'None' && productionValue !== 'None' && writingValue !== 'None') {
      setGetObjGroup([{
        language: languageValue, 
        listening: listeningValue, 
        reading: readingValue,
        interaction: interactionValue, 
        production: productionValue, 
        writing: writingValue,
      }]);
    }
    setLanguageValue('None');
    setListeningValue('None');
    setReadingValue('None');
    setInteractionValue('None');
    setProductionValue('None');
    setWritingValue('None');
  }
  
  return (
    <>
    <div className="form__candidate-optional-group">
      <Button  classNameBtn="button__link-primary" id="delete" type="button" text="Delete" icon=" delete"   />
      <Select 
        label="Language"
        id="language" 
        value={languageValue} 
        options={props.languagesDataSelect} 
        onChange={(e) => setLanguageValue(e.target.value)} 
      />
      <Link to="https://europa.eu/europass/en/document-library">Veja como preencher</Link>
      <Select 
        label="Undertanding - Listening"
        id="listening" 
        value={listeningValue} 
        options={levelSelect} 
        onChange={(e) => setListeningValue(e.target.value)} 
      />
      <Select 
        label="Undertanding - Reading"
        id="reading" 
        value={readingValue} 
        options={levelSelect} 
        onChange={(e) => setReadingValue(e.target.value)} 
      />
      <Select 
        label="Speaking - Interaction"
        id="interaction" 
        value={interactionValue} 
        options={levelSelect} 
        onChange={(e) => setInteractionValue(e.target.value)} 
      />
      <Select 
        label="Speaking - Production"
        id="production" 
        value={productionValue} 
        options={levelSelect} 
        onChange={(e) => setProductionValue(e.target.value)} 
      />
      <Select 
        label="Writing"
        id="writing" 
        value={writingValue} 
        options={levelSelect} 
        onChange={(e) => setWritingValue(e.target.value)} 
      />
    </div>
    <div className="form__candidate-optional">
      <Button  classNameBtn="button__terciary" id="add-languages" type="button"   text="Add Languages" icon="add" 
      disabled={languageValue === 'None' && listeningValue === 'None' && readingValue === 'None' && interactionValue === 'None' && productionValue === 'None' && writingValue === 'None'} onClick={(e) => addToList(e)} />
    </div>
    </>
  )
}
