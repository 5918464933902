import { MouseEvent, ChangeEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
import Button from '../../components/Button';
import Error from '../../components/Error';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import { resetPassword, login, changePassword } from '../../services/auth';

export const FormResetPassword = () => {
  const navigate = useNavigate();

  const [valueEmail, setValueEmail] = useState('');
  const [valueCandidate, setValueCandidate] = useState('');
  const [valueCode, setValueCode] = useState('');
  const [valueNewPassword, setValueNewPassword] = useState('');
  const [valueRepeatNewPassword, setValueRepeatNewPassword] = useState('');
  const [showCode, setShowCode] = useState(false);
  const [errorReset, setErrorReset] = useState('');
  const [errorChange, setErrorChange] = useState('');
  const [typeNewPassword, setTypeNewPassword] = useState('password');
  const [typeRepeatNewPassword, setTypeRepeatNewPassword] = useState('password');
  const [passwordOk, setPasswordOk] = useState(false);
  const [emailOk, setEmailOk] = useState(true);
  const [validate, setValidate] = useState({
    amount: 'false',
    lower: 'false',
    upper: 'false',
    numeric: 'false',
    equal: 'false',
  });

  const handleSendCode = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if(valueEmail == '') {
      setErrorReset('E-mail required!');
      return;
    } else {
      if (!validator.isEmail(valueEmail)) {
        setErrorReset('Digite um e-mail válido!');
        return;
      }  
    }
    setEmailOk(false);
    const reset = await resetPassword(valueEmail.trim());
    if(reset.status == 'success') {
      setShowCode(true);
      setValueCandidate(reset.candidate);
    }
    if(reset.status == 'error') {
      setErrorReset(reset.message);
      setEmailOk(true);
      setShowCode(false);
    }
  }

  const handleBackBtn = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    navigate('/');
  }

  const handleChange = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setPasswordOk(false);
    const change = await changePassword(valueEmail, valueNewPassword, valueCode, valueCandidate);
    if(change.status == 'success') {
      login(change.token);
      navigate('/profile/');
    }
    if(change.status == 'error') {
      setErrorChange(change.message);
      setPasswordOk(true);
    }

  }

  const verifyPassword = (value: string, type: string) => {
    var newPassword = false;
    var codeOk = valueCode.length == 6 || valueCode.length == 12;
    var valueComplete = valueNewPassword;
    if(type == 'new') {
      newPassword = value == valueRepeatNewPassword;
      valueComplete = value;
      setValueNewPassword(value);
      let lower = validator.isStrongPassword(value, {
        minLength: 0,
        minLowercase: 1,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0
      });
      let amount = validator.isStrongPassword(value, {
        minLength: 6,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0
      });
      let upper = validator.isStrongPassword(value, {
        minLength: 0,
        minLowercase: 0,
        minUppercase: 1,
        minNumbers: 0,
        minSymbols: 0
      });
      let numeric = validator.isStrongPassword(value, {
        minLength: 0,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 1,
        minSymbols: 0
      });
      setValidate(prev => ({...prev, lower: lower.toString()}));
      setValidate(prev => ({...prev, amount: amount.toString()}));
      setValidate(prev => ({...prev, upper: upper.toString()}));
      setValidate(prev => ({...prev, numeric: numeric.toString()}));
    }

    if(type == 'repeat') {
      newPassword = value == valueNewPassword;
      setValueRepeatNewPassword(value);
    }

    if(type == 'code') {
      setValueCode(value);
      codeOk = value.length == 6 || value.length == 12;
      newPassword = valueNewPassword == valueRepeatNewPassword;
    }

    let complete = validator.isStrongPassword(valueComplete, {
      minLength: 6,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0
    });

    if(complete && newPassword && codeOk) {
      setPasswordOk(true);
    } else  {
      setPasswordOk(false);
    }

  }

  const viewPassword = (e: MouseEvent<HTMLElement>, type: string): void => {
    e.preventDefault();
    if(type == 'new') {
      if(typeNewPassword == 'password') {
        setTypeNewPassword('text');
        setTypeRepeatNewPassword('password');
      } else {
        setTypeNewPassword('password');
      }
    }

    if(type == 'repeat') {
      if(typeRepeatNewPassword == 'password') {
        setTypeRepeatNewPassword('text');
        setTypeNewPassword('password');
      } else {
        setTypeRepeatNewPassword('password');
      }
    }

  }

  return (
    <>
    {!showCode &&
      <form className="form__reset">
        <Input
          label="E-mail"
          id="email" 
          type="email" 
          value={valueEmail} 
          placeholder="email@dominio.com.br"
          onChange={(e) => setValueEmail(e.target.value)}         
        />
        <div className="form__reset__click">
          <Button  
            classNameBtn={`button__${(emailOk ? 'primary' : 'secondary')}`}
            id="login-btn"
            type="submit"
            text="Alterar a senha"
            onClick={(e) => handleSendCode(e)} 
            disabled={!emailOk}
          />
          <Button  
            classNameBtn="button__secondary"
            id="cancel-btn" 
            type="button" 
            text="Voltar"
            onClick={(e) => handleBackBtn(e)}    
            disabled={!emailOk}
          />
        </div>
        <Error
          message={errorReset}
        />
      </form>
    }

    {showCode &&
      <form className="form__change">
        <div className='form__left'>
          <div className='form__message'>
            Foi enviado um e-mail com o código para a troca da senha!
            {/*Digite o código passado pela Scorpion para poder trocar a senha!*/}
          </div>
          <Input
            label="Code"
            id="code" 
            type="text" 
            value={valueCode} 
            placeholder="Digite o código!"
            onChange={(e) => verifyPassword(e.target.value, 'code')}         
          />
          <div className='form__change__inputIcon'>
            <Input
              label="New Password"
              id="newpassword" 
              type={typeNewPassword} 
              value={valueNewPassword} 
              onChange={(e) => verifyPassword(e.target.value, 'new')}         
            />
            <Icon icon={typeNewPassword == 'password' ? 'visibility' : 'visibility_off'} onClick={(e) => viewPassword(e, 'new')}/>
          </div>
          <div className='form__change__inputIcon'>
            <Input
              label="Repeat New Password"
              id="repeatnewpassword" 
              type={typeRepeatNewPassword} 
              value={valueRepeatNewPassword} 
              onChange={(e) => verifyPassword(e.target.value, 'repeat')}         
            />
            <Icon icon={typeRepeatNewPassword == 'password' ? 'visibility' : 'visibility_off'} onClick={(e) => viewPassword(e, 'repeat')}/>
          </div>
          <Error
            message={errorChange}
          />
          <div className="form__click">
            <Button  
              classNameBtn={`button__${(passwordOk ? 'primary' : 'secondary')}`}
              id="login-btn"
              type="submit"
              text="Alterar"
              disabled={!passwordOk}
              onClick={(e) => handleChange(e)} 
            />
          </div>
        </div>
        <div className='form__rigth'>
          <div className='form__password'>
            <div className={`form__password__${validate.amount}`}>
              <Icon icon={validate.amount == 'true' ? 'verified' : 'cancel'} classNameIcon='icon__verify'/>
              Mínimo de 6 dígitos
            </div>
            <div className={`form__password__${validate.upper}`}>
              <Icon icon={validate.upper == 'true' ? 'verified' : 'cancel'} classNameIcon='icon__verify'/>
              Letra maiúscula
            </div>
            <div className={`form__password__${validate.lower}`}>
              <Icon icon={validate.lower == 'true' ? 'verified' : 'cancel'} classNameIcon='icon__verify'/>
              Letra minúscula
            </div>
            <div className={`form__password__${validate.numeric}`}>
              <Icon icon={validate.numeric == 'true' ? 'verified' : 'cancel'} classNameIcon='icon__verify'/>
              Número
            </div>
          </div>
        </div>
      </form>
    }
    </>
  )
}
