import api from './api';
import { entryPoint } from './api';

export const isAuthenticated = () => localStorage.getItem('access_token') !== null;
export const getToken = () => localStorage.getItem('access_token');
export const login = token => {
  localStorage.setItem('access_token', token);
};
export const logout = () => {
  localStorage.removeItem('access_token');
};

export const loginUser = async (username, password) => {
	const formJobDetail = new FormData();
	formJobDetail.append('type', 'login');
	formJobDetail.append('username', username);
	formJobDetail.append('password', password);

	return await api.post(`${entryPoint}portalCandidates`, formJobDetail, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		if(response.data.status === 'success') {
			login(response.data.token);
		}
		if(response.data.status === 'error') {
			logout();
		}
		return response.data;
	})
	.catch(function (error) {
		console.log(error);
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});
}

export const resetPassword = async (username) => {
	const formJobDetail = new FormData();
	formJobDetail.append('type', 'resetPassword');
	formJobDetail.append('username', username);

	return await api.post(`${entryPoint}portalCandidates`, formJobDetail, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		return response.data;
	})
	.catch(function (error) {
		console.log(error);
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});
}

export const changePassword = async (username, newPassword, code, candidate) => {
	const formJobDetail = new FormData();
	formJobDetail.append('type', 'changePassword');
	formJobDetail.append('username', username);
	formJobDetail.append('newPassword', newPassword);
	formJobDetail.append('code', code);
	formJobDetail.append('candidate', candidate);

	return await api.post(`${entryPoint}portalCandidates`, formJobDetail, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		}
	})
	.then(function (response) {
		return response.data;
	})
	.catch(function (error) {
		console.log(error);
		throw new Error("Serviço indisponível, tente novamente mais tarde")
	});

}