import { faFacebookF, faInstagram, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import Icon from '../Icon'
import Logo from '../Logo';


export const Footer = () => {

  return (
    <footer className="footer">
      <div className="footer__contact">
        <nav className="footer__contact-nav">
          <ul className="footer__contact-nav--list">
            {/* <li className="footer__contact-nav--list-item">
              <Icon icon="phone" classNameIcon="icon__round" />
              <p>Phone | Whatsapp</p>
              <Link to="https://api.whatsapp.com/send?phone=+351912989108&text=Estou%20interessado%20nos%20vossos%20servi%C3%A7os" className="footer__contact-nav--list-item--data">(+351) 912 989 108</Link>
            </li> */}
            <li className="footer__contact-nav--list-item">
              <Icon icon="mail" classNameIcon="icon__round"/>
              <p>Email</p>
              <Link to="mailto:scorpion@scorpion.pt" className="footer__contact-nav--list-item--data">scorpion@scorpion.pt</Link>
            </li>
            <li className="footer__contact-nav--list-item">
              <Icon icon="schedule" classNameIcon="icon__round"/>
              <p>Horário</p>
              <div className="footer__contact-nav--list-item--data">
                <p>Segunda-Sexta</p>
                <p>8h - 18pm</p>
              </div>
            </li>
          </ul>
        </nav>
        <ul className="footer__social-list">
          <li className="footer__social-list-item">
            <Link to="https://www.facebook.com/circlescorpion">
            <FontAwesomeIcon icon={faFacebookF} size="xl" style={{color: "#eeeeee",}} />
            </Link>
          </li>
          <li className="footer__social-list-item">
            <Link to="https://www.linkedin.com/company/18466660">
              <FontAwesomeIcon icon={faLinkedinIn} size="xl" style={{color: "#eeeeee",}} />
            </Link>
          </li>
          <li className="footer__social-list-item">
            <Link to="https://www.instagram.com/scorpioncircle/">
              <FontAwesomeIcon icon={faInstagram} size="xl" style={{color: "#eeeeee",}} />
            </Link>
          </li>
          <li className="footer__social-list-item">
            <Link to="https://www.youtube.com/channel/UCM1YHX3w9Ni7y-943TmcHTg">
              <FontAwesomeIcon icon={faYoutube} size="xl" style={{color: "#eeeeee",}} />
            </Link>
          </li>
          <li className="footer__social-list-item">
            <Link to="https://twitter.com/circlescorpion">
              <FontAwesomeIcon icon={faTwitter} size="xl" style={{color: "#eeeeee",}} />
            </Link>
          </li>
        </ul>
        <div className="footer__logo">
          <img src="https://scorpioncircle.com/wp-content/uploads/2020/07/logo-branco-980x445.png" alt="Logo Scorpion" />
        </div>
      </div>
      <div className="footer__address">
        <ul className="footer__address-list">
          <li className="footer__address-list-item">
            <img src="https://scorpioncircle.com/wp-content/uploads/2020/07/icon-portugal-1.png" alt="Lisboa" />
            <div>
              <p>LISBOA</p>
              <Link to="https://goo.gl/maps/TakxcaGvQ3WQWxxx6">Estrada de Moscavide, 60A</Link>
              <Link to="https://api.whatsapp.com/send?phone=+351912989108&amp;text=Estou interessado nos vossos serviços">
                <Icon icon="phone_iphone"/>
                +351 300 509 569
              </Link>
            </div>
          </li>
          <li className="footer__address-list-item">
            <img src="https://scorpioncircle.com/wp-content/uploads/2020/07/icon-brazil.png" alt="São Paulo" />
            <div>
              <p>SÃO PAULO</p>
              <Link to="https://goo.gl/maps/sdTFLfn89HueFgAB8">Av. Paulista, 171</Link>
              <div>
                <Icon icon="phone_iphone"/>
                +55 11 4210 4556
              </div>
            </div>
          </li>
          <li className="footer__address-list-item">
            <img src="https://scorpioncircle.com/wp-content/uploads/2020/07/icon-usa.png" alt="Lisboa" />
            <div>
              <p>LAGUNA BEACH</p>
              <Link to="https://goo.gl/maps/Nkq9AtSmRZV1jLzDA">South Coast Highway, 1968</Link>
              <div>
                <Icon icon="phone_iphone"/>
                +1 949 516 9090
              </div>
            </div>
          </li>
        </ul>
      </div>
    </footer>
  )
}
