import { ChangeEventHandler, FC } from "react";

interface SelectProps {
  id: string;
  label?: string;
  value: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  options: { label: string; value: string; }[];
  onChange?: ChangeEventHandler<HTMLSelectElement>;
}

export interface OptionProps {
  label: string;
  value: string;
}

export const Select: FC<SelectProps> = (props: SelectProps) => {
  return (
    <div className="select__group">
      <label className="select__label" htmlFor={props.id}>
        {props.label}
      </label>
      <select className="select" value={props.value} name={props.name} onChange={props.onChange}>
        {props.options.map((option: OptionProps, index: number) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};
