import { useEffect, useState } from 'react';
import { ICertification } from '../../../interfaces/ICertification';
import Icon from '../../../components/Icon';

export const DataCertificationCandidateProfile = (props: {removeIcon: any, getData: any, value: any}) => {
  const [getObjGroup, setGetObjGroup] = useState<ICertification[]>([]);

  useEffect(() => {
    props.getData(getObjGroup, 'certificationDetails');
  }, [getObjGroup])
  
  const handleDeleteItem = (index: number) => {
    setGetObjGroup(props.value);
    const newObjGroup = [...props.value];
    newObjGroup.splice(index, 1);
    setGetObjGroup(newObjGroup);
  }

  return (
    <ul className="profile__group-list">
      {props.value?.map((certification: ICertification, index: number) => {
        return (
          <li key={index} className="profile__group-list-item">
            <div className="profile__group-list-item-text">
              <p className="profile__data">{certification.name}</p>
              <p className="profile__data">
              <span>Company:</span>
                {certification.company}
              </p>
              <p className="profile__data">
                <span>Certificate Code:</span>
                {certification.certificate_code}
              </p>
              <p className="profile__data">
                <span>Date:</span>
                {certification.date}
              </p>
            </div>
            {props.removeIcon &&
              <Icon icon=" delete"   onClick={() => handleDeleteItem(index)}/>
            }
          </li>
        )
      })}
    </ul>
  )
}
