import Input from '../../../components/Input';
import Select from '../../../components/Select';
import IntlTelInput from 'react-intl-tel-input-18';

const genderSelect = [
  { label: 'None', value: 'None' },
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];

const maritalSelect = [
  { label: 'None', value: 'None' },
  { label: 'Married', value: 'Married' },
  { label: 'Single', value: 'Single' },
  { label: 'Engaged', value: 'Engaged' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Widowed', value: 'Widowed' },
];

export const FormBasicInfoCandidateProfile = (props: {countriesDataSelect: {label: string, value: string}[],  getData: any, value: any}) => {

  return (
    <div className="form__candidate-optional">
      <Input id="first-name" type="text" value={props.value.firstName} placeholder="" label="First Name" required={true} name="firstName" onChange={(e) => props.getData(e, "basicInfo")} />
      <Input id="last-name" type="text" value={props.value.lastName} placeholder="" label="Last Name" required={true} name="lastName"onChange={(e) => props.getData(e, "basicInfo")} />
      <Input id="email" type="email" value={props.value.email} placeholder="" label="E-mail" required={true} name="email" onChange={(e) => props.getData(e, "basicInfo")} />
      <Input id="birth" type="date" value={props.value.birth} placeholder="dd-mm-yyyy" label="Birth" required={true} name="birth" onChange={(e) => props.getData(e, "basicInfo")} />
      <div className="input__group">
        <label className="input__label" htmlFor="mobile">Mobile</label>
        <IntlTelInput 
          preferredCountries={['pt', 'br']}
          value={props.value.mobile}
          fieldId="mobile"
          fieldName="mobile"
          inputClassName="input"
          onPhoneNumberChange={(_valid, _value) => props.getData({name: "mobile", value: _value}, "basicInfo")}
        />
      </div>
      <Input id="linkedin" type="text" value={props.value.linkedin} placeholder="" label="LinkedIn" required={true} name="linkedin" onChange={(e) => props.getData(e, "basicInfo")} />
      <Select 
        label="Birth Country"
        id="birth-country" 
        value={props.value.birthCountry} 
        name="birthCountry"
        options={props.countriesDataSelect} 
        onChange={(e) => props.getData(e, "basicInfo")}
      /> 
      <Select 
        label="Citizenship Country"
        id="citizenship-country" 
        value={props.value.citizenshipCountry} 
        name="citizenshipCountry" 
        options={props.countriesDataSelect} 
        onChange={(e) => props.getData(e, "basicInfo")}
      /> 
      <Select 
        label="Gender"
        id="gender" 
        value={props.value.gender} 
        name="gender"
        options={genderSelect} 
        onChange={(e) => props.getData(e, "basicInfo")}
      />
      <Select 
        label="Marital Status"
        id="marital-status" 
        value={props.value.marital} 
        name="marital"
        options={maritalSelect} 
        onChange={(e) => props.getData(e, "basicInfo")}
      />
    </div>
  )
}
